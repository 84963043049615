// import { useState } from "react";
// import SETTINGS from "../../settings";
// import MyForm from "../../components/MyForm";
// import reqEditFields from "../../form-fields/req-edit";
import { Stack } from "@mui/material";
import { AddForm } from "../../components/req/form/add-form/Addform";
import { FormProvider } from "../../context/formContext/ReqAddContext";

export default function ReqAdd() {
  //   const [req, setReq] = useState({});
  //   const baseErrors: any = {};
  //   const [errors, setErrors] = useState(baseErrors);

  return (
    <Stack
      sx={{ backgroundColor: "#fff", padding: "20px", borderRadius: "10px" }}
    >
      <FormProvider>
        <AddForm />
      </FormProvider>
    </Stack>
    // <MyForm
    //   fields={reqEditFields}
    //   method="POST"
    //   returnUrl={`/reqs/`}
    //   formUrl={`${SETTINGS.SITE_URL}/api/v1/reqs/`}
    //   state={req}
    //   setState={setReq}
    //   firstUpdated={true}
    //   errors={errors}
    //   setErrors={setErrors}
    // />
  );
}
