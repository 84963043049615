import { useState } from "react";
import SETTINGS from "../../settings";
import MyForm from "../../components/MyForm";
import cAddFields from "../../form-fields/c-add";

export default function ContainerAdd() {
  const baseC: any = {};
  const [c, setC] = useState(baseC);
  const baseErrors: any = {};
  const [errors, setErrors] = useState(baseErrors);
  return (
    <>
      <MyForm
        fields={cAddFields}
        method="POST"
        returnUrl={`/containers/`}
        formUrl={`${SETTINGS.SITE_URL}/api/v1/containers/`}
        state={c}
        setState={setC}
        errors={errors}
        setErrors={setErrors}
      />
    </>
  );
}
