import { useCallback, useMemo, useState } from "react";
import { useDisclosure } from "../useDisclosure";

interface RouteManagementResult {
  routeState: string;
  modalRouteOpened: boolean;
  modalRouteActions: {
    open: () => void;
    close: () => void;
  };
  handleRouteChange: (value: string) => void;
  handleRouteConfirm: () => string;
}
export const useRouteManagement = (
  initialKind: string,
  status: string
): RouteManagementResult => {
  const [routeState, setRouteState] = useState<string>(initialKind);
  const [selectedRoute, setSelectedRoute] = useState<string>(initialKind);
  const [modalRouteOpened, modalRouteActions] = useDisclosure(false);

  const handleRouteChange = useCallback(
    (value: string) => {
      if (
        value !== routeState &&
        status !== "Завершена" &&
        status !== "Отменена"
      ) {
        modalRouteActions.open();
        setSelectedRoute(value);
      }
    },
    [routeState, status]
  );

  const handleRouteConfirm = useCallback(() => {
    setRouteState(selectedRoute);
    modalRouteActions.close();
    return selectedRoute;
  }, [selectedRoute]);

  return useMemo(
    () => ({
      routeState,
      modalRouteOpened,
      modalRouteActions,
      handleRouteChange,
      handleRouteConfirm,
    }),
    [
      routeState,
      modalRouteOpened,
      modalRouteActions,
      handleRouteChange,
      handleRouteConfirm,
    ]
  );
};
