import { FC } from "react";
import { ModalMui } from "../../Modal";
import { Group } from "../../../ui/mui/Group/Group";
import { Button } from "@mui/material";

export type ModalConfirm = {
  opened: boolean;
  close: () => void;
  onConfirm: () => void;
  title: string;
  confirmLabel: string;
};
export const ModalConfirm: FC<{ config: ModalConfirm }> = ({ config }) => {
  const { opened, onConfirm, close, title, confirmLabel } = config;

  return (
    <ModalMui opened={opened} onClose={close} title={title} w={420}>
      <Group gap={10} justify="center" h={100} align="center">
        <Button variant="contained" onClick={onConfirm}>
          {confirmLabel}
        </Button>
        <Button variant="outlined" onClick={close}>
          Отмена
        </Button>
      </Group>
    </ModalMui>
  );
};
