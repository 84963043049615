import { Box, styled } from "@mui/material";
import { DatePicker } from "../../date-picker/DatePicker";
import { FC, useCallback, useState } from "react";
import dayjs from "dayjs";
import { myFetch } from "../../../utils";
import SETTINGS from "../../../settings";
import { useNavigate } from "react-router-dom";
import { ERROR_MESSAGES } from "../../../constants/constants";

const Wrapper = styled(Box)(({}) => ({
  display: "flex",
  flexDirection: "column",
  padding: "0 40px 40px 40px",
  minWidth: "670px",
  boxSizing: "border-box",
  gap: "30px",
}));

const Content = styled("div")(({}) => ({
  display: "flex",
  flexWrap: "wrap",
  maxWidth: "590px",
  gap: "10px",
}));

// const Input = styled(TextField)(({}) => ({
//   width: "370px",
//   ".MuiInputLabel-root": {
//     color: "#B2B3C3 !important",
//     opacity: "1 !important ",
//     fontSize: "10px",
//   },
//   ".MuiInputLabel-root[data-shrink='true']": {
//     top: "15px !important",
//   },
//   ".MuiInputBase-root": {
//     backgroundColor: "transparent",
//     border: "1px solid #F0F0F7",
//     borderRadius: "10px",

//     fieldset: {
//       borderWidth: 0,
//       height: "100%",
//       top: 0,

//       legend: {
//         display: "none",
//       },
//     },
//   },
//   ".MuiInputBase-root input": {
//     padding: "22px 0 4px 16px",
//     fontSize: "12px",
//   },
// }));

const Buttons = styled("div")(({}) => ({}));

interface RescheduleDateProps {
  closeModal: () => void;
  defaultValue?: string;
  reqId?: number;
  setErrorMessage?: React.Dispatch<React.SetStateAction<string>>;
  openModal?: { open: () => void };
}

export const RescheduleDate: FC<RescheduleDateProps> = ({
  closeModal,
  defaultValue,
  reqId,
  setErrorMessage,
  openModal,
}) => {
  const navigate = useNavigate();
  const [newDate, setNewDate] = useState<string | undefined>(defaultValue);

  const handleChangeDatePicker = useCallback((e: dayjs.Dayjs | null) => {
    setNewDate(e?.format("YYYY-MM-DD"));
  }, []);

  const handleClickSave = async () => {
    if (newDate === defaultValue) {
      if (setErrorMessage && openModal) {
        setErrorMessage(ERROR_MESSAGES.NO_DATE);
        openModal.open();
        return;
      }
    }

    if (!reqId) {
      console.error("reqId is required");
      return;
    }

    const data = {
      req_date: newDate,
    };
    const res = await myFetch(
      `${SETTINGS.SITE_URL}/api/v1/reqs/${reqId}/`,
      {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
      navigate
    );

    if (res.ok) navigate("/reqs");

    closeModal();
  };

  return (
    <Wrapper>
      <Content>
        {/* <Input label="Причина" onClick={handleChangeInput} /> */}
        <DatePicker
          onChange={handleChangeDatePicker}
          label="Новая дата рейса"
          defaultValue={defaultValue}
        />
      </Content>
      <Buttons>
        <button
          type="button"
          className="form__buttons-btn form__buttons-btn--primary d-flex items-center transition"
          onClick={handleClickSave}
        >
          Сохранить
        </button>
      </Buttons>
    </Wrapper>
  );
};
