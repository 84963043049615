import { Snackbar, Stack } from "@mui/material";
import { Group } from "../../../../ui/mui/Group/Group";
import { FC, useCallback, useMemo } from "react";
import { FormConfirmModals, Req, ReqForm } from "../../../../interfaces/req";
import { Routes } from "./Routes";
import { ViewingField } from "../../../../ui/Fields/ViewingField";
import { SelectForm } from "../../../../ui/mui/Select/Select";
import { useDisclosure } from "../../../../hooks/useDisclosure";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { getDateFormat } from "../../../../utils/getDateFormat";
import { SaveCancel } from "../../../buttons/SaveCancel";
import { ModalMui } from "../../../Modal";
import { RescheduleDate } from "../../reschedule-date/RescheduleDate";
import { useReqEditForm } from "../../../../hooks/Reqs/useReqEditForm";
import { useDrivers } from "../../../../hooks/Reqs/useDrivers";
import { RouteState } from "./RouteState";
import { ClientSection } from "./sections/ClientSection";
import { PaymentSection } from "./sections/PaymentSection";
import { ServiceSection } from "./sections/ServiceSection";
import { Title } from "../../../../ui/mui/Title/Title";
import { useRouteManagement } from "../../../../hooks/Reqs/useRouteManagement";
import { MODAL_EDIT_CONFIGS } from "../../../../params/req";
import { EditConfirmModals } from "../../../modals/modal-confirm/EditConfirmModals";
import {
  DATE_FORMAT_OPTIONS,
  SNACKBAR_CONFIG,
} from "../../../../constants/constants";
import { useConfirmModals } from "../../../../hooks/Modals/useConfirmModals";

type EditReqFormProps = {
  req: Req;
};

export const EditReqForm: FC<EditReqFormProps> = ({ req }) => {
  const {
    client,
    address_location,
    source,
    dispatcher1,
    kind,
    driver,
    additional_services,
    req_date,
    status,
  } = req;
  const isReqFinished = status === "Завершена" || status === "Отменена";
  const [modalDateOpened, modalDateActions] = useDisclosure(false);
  const { modalStates, toggleModal } = useConfirmModals();
  const [snackbarOpened, snackbarActions] = useDisclosure(false);

  const { setFormData, handleSubmit, snackbarMessage, setSnackBarMessage } =
    useReqEditForm(req, snackbarActions.open);

  const {
    routeState,
    modalRouteOpened,
    modalRouteActions,
    handleRouteChange,
    handleRouteConfirm,
  } = useRouteManagement(kind, status);

  const { driverList, vehicleInfo, handleChangeDriver } = useDrivers(
    driver,
    setFormData
  );

  const modalConfirmConfig: FormConfirmModals = useMemo(
    () => ({
      configs: MODAL_EDIT_CONFIGS,
      states: { ...modalStates, route: modalRouteOpened },
      handlers: {
        route: {
          onConfirm: () => {
            const newRoute = handleRouteConfirm();
            setFormData(
              (prev) => ({ ...(prev ?? {}), kind: newRoute } as ReqForm)
            );
          },
          onClose: modalRouteActions.close,
        },
        date: {
          onConfirm: () => {
            toggleModal("date");
            modalDateActions.open();
          },
          onClose: () => toggleModal("date"),
        },
      },
    }),
    [modalStates, modalRouteOpened]
  );

  const modalDateConfig = {
    opened: modalDateOpened,
    onClose: modalDateActions.close,
    title: "Изменения даты рейса",
  };

  const handleFormDataChange = useCallback(
    (field: string) => (value: any) => {
      setFormData((prev) => ({ ...(prev ?? {}), [field]: value } as ReqForm));
    },
    []
  );

  const handleClickDate = () => {
    if (status !== "Завершена" && status !== "Отменена") {
      toggleModal("date");
    }
  };

  return (
    <form onSubmit={handleSubmit} id="reqEdit">
      <Snackbar
        open={snackbarOpened}
        autoHideDuration={SNACKBAR_CONFIG.autoHideDuration}
        onClose={snackbarActions.close}
        anchorOrigin={SNACKBAR_CONFIG.anchorOrigin}
        message={snackbarMessage}
      />
      <EditConfirmModals {...modalConfirmConfig} />
      <ModalMui {...modalDateConfig}>
        <RescheduleDate
          closeModal={modalDateActions.close}
          defaultValue={req_date}
          reqId={req.id}
          setErrorMessage={setSnackBarMessage}
          openModal={snackbarActions}
        />
      </ModalMui>
      <Stack gap={3}>
        <ClientSection
          client={client}
          source={source}
          dispatcher={dispatcher1}
        />
        <Stack gap={2}>
          <Title variant="h4">Маршрут</Title>
          <Routes kind={routeState} onClick={handleRouteChange} />
          <RouteState
            routeState={routeState}
            address_location={address_location}
            isReqFinished={isReqFinished}
          />

          <Group gap={10}>
            <ViewingField
              disabled={isReqFinished}
              label="Дата рейса"
              value={getDateFormat(req_date, DATE_FORMAT_OPTIONS)}
              w={10}
              Icon={<CalendarMonthIcon onClick={handleClickDate} />}
            />
            <SelectForm
              list={driverList}
              w={190}
              label="Водитель"
              defaultValue={driver?.id}
              disabled={isReqFinished}
              onChange={handleChangeDriver as any}
            />
            <ViewingField label="Автомашина" value={vehicleInfo} w={54} />
          </Group>

          <ViewingField
            label="Вывоз мусора"
            value="Полигон"
            w={20}
            Icon={<HelpOutlineIcon sx={{ width: "16px", opacity: "0.4" }} />}
          />
        </Stack>
        <PaymentSection
          onChange={handleFormDataChange}
          req={req}
          disabled={isReqFinished}
        />
        <ServiceSection
          initialState={additional_services}
          isFinishedStatus={Boolean(isReqFinished)}
        />
        <Stack gap={2}>
          <Title variant="h4" developed>
            Документы
          </Title>
        </Stack>
        <SaveCancel formId="reqEdit" />
      </Stack>
    </form>
  );
};
