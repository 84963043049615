import { useCallback, useState } from "react";
import { Additional_services } from "../../interfaces/req";

export const useServices = (initialServices: Additional_services[] | []) => {
  const [services, setServices] = useState(initialServices);

  const addService = useCallback(() => {
    const service: Additional_services = {
      id: Date.now(),
      service: null,
      created_at: "",
      updated_at: "",
      cost: null,
    };
    setServices([...services, service]);
  }, [services]);

  const removeService = useCallback(
    (id: number) => {
      const mod = services.filter((service) => service.id !== id);
      setServices(mod);
    },
    [services]
  );

  return { services, addService, removeService };
};
