import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Company } from "../../interfaces/company";
import { myFetch } from "../../utils";
import SETTINGS from "../../settings";

type ClientSelectHook = (id: number | null) => {
  addresses_objects: { id: number; label: string }[];
  company: Company | null;
};

export const useClientSelect: ClientSelectHook = (id) => {
  const navigate = useNavigate();
  const [company, setCompany] = useState<Company | null>(null);

  useEffect(() => {
    let isMounted = true;
    const fetchCompany = async () => {
      if (!id) {
        setCompany(null);
        return;
      }

      const response = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/companies/${id}`,
        {},
        navigate
      );
      const jsonData = await response.json();
      if (isMounted) {
        setCompany(jsonData);
      }
    };
    fetchCompany();

    return () => {
      isMounted = false;
    };
  }, [id, navigate]);

  const addresses_objects = useMemo(
    () =>
      company?.company_objects?.map((obj) => ({
        id: +obj.id,
        label: obj.address,
      })) || [],
    [company]
  );

  return useMemo(
    () => ({ addresses_objects, company }),
    [addresses_objects, company]
  );
};
