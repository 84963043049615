import { Stack } from "@mui/material";
import { Title } from "../../../../../ui/mui/Title/Title";
import { RouteType } from "../../../../select/RouteType";
import { FC } from "react";

export const RouteSection = () => {
  return (
    <Stack gap={3}>
      <Title variant="h4">Маршрут</Title>
      <RouteType />
    </Stack>
  );
};
