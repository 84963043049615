import { Stack } from "@mui/material";
import { Title } from "../../../../../ui/mui/Title/Title";

export const ServiceSection = () => {
  return (
    <Stack gap={3}>
      <Title variant="h4" developed>
        Дополнительные услуги
      </Title>
    </Stack>
  );
};
