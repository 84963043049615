import { styled } from "@mui/material";
import { ViewingField } from "../../../ui/Fields/ViewingField";
import { Additional_services } from "../../../interfaces/req";
import { FC, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { SelectForm } from "../../../ui/mui/Select/Select";

const Wrapper = styled("div")(({}) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  borderRadius: "20px",
  backgroundColor: "#F6F6F9",
  height: "64px",
  padding: "0 10px",
}));
const ButtonBox = styled("div")(({}) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#EEEEF3",
  borderRadius: "10px",
  height: "44px",
  width: "30px",
  cursor: "pointer",
}));

type ExtraService = {
  service: Additional_services;
  onRemove: (id: number) => void;
  disabled?: boolean;
};

const priceServices: { [key: string]: string } = {
  1: "2000",
  2: "1000",
};
export const ExtraService: FC<ExtraService> = ({
  service,
  onRemove,
  disabled,
}) => {
  const { id, cost } = service;

  const [selectValue, setSelectValue] = useState("");

  return (
    <Wrapper>
      <SelectForm
        label={"Наименование услуги"}
        defaultValue={id}
        w={1030}
        disabled={disabled}
        list={[
          { id: 1, label: "Перестановка контейнера" },
          { id: 2, label: "Перегруз" },
        ]}
        bg="#fff"
        onChange={(value) => setSelectValue(value.target.value)}
      />
      <ViewingField
        label={"Стоимость"}
        value={cost ? String(cost) : priceServices[selectValue]}
        bg="#fff"
        w={20}
      />
      <ButtonBox onClick={() => (disabled ? {} : onRemove(id))}>
        <DeleteIcon sx={{ width: "16px" }} />
      </ButtonBox>
    </Wrapper>
  );
};
