import { Stack, styled } from "@mui/material";
import { FC, ReactNode } from "react";

type ViewingFieldProps = {
  w?: number;
  label: string;
  value: string | undefined;
  disabled?: boolean;
  Icon?: ReactNode;
  bg?: string;
};

const Field = styled("div")<Pick<ViewingFieldProps, "w" | "disabled" | "bg">>(
  ({ w, disabled, bg }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "7px 10px 5px 10px",
    height: "var(--input-height)",
    border: "1px solid var(--input-border-color)",
    borderRadius: "10px",
    width: `${w}%`,
    backgroundColor: disabled ? "#F6F6F9" : bg ? bg : "#transparant",
    boxShadow: disabled
      ? "inset 1px 1px 2px 0px #c9c9c9"
      : "4px 3px 2px #F6F6F9",
  })
);
const Label = styled("div")(({}) => ({
  fontSize: "var(--input-label-font-size)",
  color: "#B2B3C3",
}));
const Value = styled("div")(({}) => ({
  fontSize: "var(--input-font-size)",
}));
const IconBox = styled("div")(({}) => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
}));

export const ViewingField: FC<ViewingFieldProps> = ({
  w,
  label,
  value,
  disabled = true,
  Icon,
  bg,
}) => {
  return (
    <Field w={w} disabled={disabled} bg={bg}>
      <Stack gap={0.5}>
        <Label>{label}</Label>
        <Value>{value}</Value>
      </Stack>
      <IconBox>{Icon}</IconBox>
    </Field>
  );
};
