import { Stack, styled } from "@mui/material";
import { Title } from "../../../../../ui/mui/Title/Title";
import { ExtraService } from "../../../extra-service/ExtraService";
import { useServices } from "../../../../../hooks/Additional-Service/useServices";
import { FC } from "react";
import { Additional_services } from "../../../../../interfaces/req";

const ButtonAddService = styled("div")(({}) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "24px",
  width: "130px",
  borderRadius: "12px",
  border: "1px solid #44A77D",
  color: "#44A77D",
  fontWeight: 600,
  fontSize: "12px",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
}));

type ServiceSectionProps = {
  initialState: Additional_services[];
  isFinishedStatus: boolean;
};
export const ServiceSection: FC<ServiceSectionProps> = ({
  initialState,
  isFinishedStatus,
}) => {
  const { services, addService, removeService } = useServices(initialState);
  return (
    <Stack gap={2}>
      <Title variant="h4" developed>
        Дополнительные услуги
      </Title>
      <Stack gap={2}>
        {services.map((service) => (
          <ExtraService
            service={service}
            onRemove={removeService}
            disabled={isFinishedStatus}
          />
        ))}
        {!isFinishedStatus && (
          <ButtonAddService onClick={addService}>
            Добавить услугу
          </ButtonAddService>
        )}
      </Stack>
    </Stack>
  );
};
