import { Tokens } from '../interfaces/tokens';
import SETTINGS from '../settings';

export const refreshToken = async (): Promise<string | null> => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      throw new Error('Отсутствует refresh token');
    }

    const response = await fetch(`${SETTINGS.SITE_URL}/api/token/refresh/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh: refreshToken }),
    });

    if (!response.ok) {
      throw new Error('Ошибка обновления токена');
    }

    const tokens: Tokens = await response.json();
    localStorage.setItem('accessToken', tokens.access);
    return tokens.access;
  } catch (error) {
    console.error('Ошибка обновления токена', error);
    return null;
  }
};