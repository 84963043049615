import { RouteSection } from "./sections/RouteSection";
import { useAddFormContext } from "../../../../hooks/Context/useAddFormContext";
import { ClientSection } from "./sections/ClientSection";
import { Snackbar, Stack } from "@mui/material";
import { ActionSection } from "./sections/ActionSection";
import { ExecuterSection } from "./sections/ExecuterSection";
import { PaymentSection } from "./sections/PaymentSection";
import { CommentSection } from "./sections/CommentSection";
import { ServiceSection } from "./sections/ServiceSection";
import { SaveCancel } from "../../../buttons/SaveCancel";
import { SNACKBAR_CONFIG } from "../../../../constants/constants";
import { useDisclosure } from "../../../../hooks/useDisclosure";

export const AddForm = () => {
  const { formData, snackbarMessage, handleSubmit } = useAddFormContext();
  const [snackbarOpened, snackbarActions] = useDisclosure(false);
  // console.log(formData);
  return (
    <form
      onSubmit={(event) => handleSubmit(event, snackbarActions.open)}
      id="reqAdd"
    >
      <Stack gap={4}>
        <Snackbar
          open={snackbarOpened}
          autoHideDuration={SNACKBAR_CONFIG.autoHideDuration}
          onClose={snackbarActions.close}
          anchorOrigin={SNACKBAR_CONFIG.anchorOrigin}
          message={snackbarMessage}
        />
        <RouteSection />
        <ClientSection />
        <ActionSection />
        <ExecuterSection />
        <PaymentSection />
        <CommentSection />
        <ServiceSection />
        <SaveCancel formId="reqAdd" />
      </Stack>
    </form>
  );
};
