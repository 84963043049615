import { useState } from "react"
import SETTINGS from "../../settings"
import MyForm from "../../components/MyForm"
import vehicleEditFields from "../../form-fields/vehicle-edit"



export default function VehicleAdd() {
    // const reqId = props.match.params.id
    const [vehicle, setVehicle] = useState({})
    const baseErrors: any = {}
    const [errors, setErrors] = useState(baseErrors)
    return <MyForm
        fields={vehicleEditFields}
        method="POST"
        returnUrl={`/vehicles/`}
        formUrl={`${SETTINGS.SITE_URL}/api/v1/vehicles/`}
        state={vehicle}
        setState={setVehicle}
        errors={errors}
        setErrors={setErrors}
    />
}
