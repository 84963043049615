"use client";
import React, { useState, FormEvent, SyntheticEvent, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { myFetch } from "../utils";
import { useNavigate } from "react-router-dom";
import { AddressSuggestions } from "react-dadata";
import AsyncAutocomplete from "./AsyncAutoComplete";

interface MyFormProps {
  fields: Array<any>;
  state: any;
  setState: Function;
  formUrl: string;
  returnUrl?: string;
  method: string;
  firstUpdated?: boolean;
  additionalFields?: any;
  errors: any;
  setErrors: Function;
}

function getValueFromState(state: any, key: string, defaultValue: any) {
  let path = key.split(".");
  let val = state;
  // console.log(path, val, 'path && val')
  for (const p of path) {
    val = val[p];
    if (typeof val === "undefined") {
      return defaultValue;
    }
    if (val === null) {
      return defaultValue;
    }
  }
  return val || defaultValue;
}

export default function MyForm({
  fields,
  state,
  setState,
  formUrl,
  returnUrl,
  method,
  firstUpdated,
  additionalFields,
  errors,
  setErrors,
}: MyFormProps) {
  // const [errors, setErrors]: [any, Function] = useState({})
  const navigate = useNavigate();

  const fun = async (e: FormEvent<HTMLElement>) => {
    const data = JSON.parse(JSON.stringify(state));
    for (let row of fields) {
      for (let field of row.fields) {
        if (field.fieldKind == "dict-select") {
          data[field.mykey] = data[field.mykey]?.id || null;
        }
      }
    }
    const res = await myFetch(
      formUrl,
      {
        method,
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
      navigate
    );
    if (res.status < 300) {
      // const req: any = await res.json()
      // setState(req)
      if (returnUrl) {
        navigate(returnUrl);
      }
    } else if (res.status == 400) {
      const errs: any = await res.json();
      setErrors(errs);
    } else {
      alert(res.status);
    }
  };

  return (
    <div className="form">
      {!state.isEmpty &&
        fields.map((row) => (
          <>
            {row.header && <h2 className="form__title">{row.header}</h2>}
            <div className="form-fields d-flex flex-wrap justify-between">
              {row.fields?.map((f: any) => {
                return (
                  <div key={f.mykey} className={`form-field ${f.customClass}`}>
                    {f.fieldKind == "select" && (
                      <>
                        <Select
                          id={`field-${f.mykey}`}
                          value={getValueFromState(state, f.mykey, "")}
                          label={f.label}
                          displayEmpty
                          error={errors[f.mykey] ? errors[f.mykey][0] : ""}
                          disabled={f.mykey.split(".").length > 1}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return (
                                <span style={{ opacity: "0.8" }}>
                                  {f.label}
                                </span>
                              );
                            }

                            return selected;
                          }}
                          onChange={(e: SelectChangeEvent<any>) => {
                            const value = e.target.value;
                            setState({
                              ...state,
                              [f.mykey]: value,
                            });
                          }}
                          className="form-field__select"
                        >
                          {f.options?.map((o: any) => {
                            return (
                              <MenuItem
                                key={`${f.mykey}-options-${o.value}`}
                                value={o[f.nameField]}
                              >
                                {o[f.nameField]}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </>
                    )}
                    {f.fieldKind === "date" && (
                      <>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label={f.label}
                            format="YYYY-MM-DD"
                            slotProps={{
                              textField: {
                                variant: "outlined",
                                error: !!errors[f.mykey],
                                helperText: errors[f.mykey],
                              },
                            }}
                            disabled={f.mykey?.split(".").length > 1}
                            value={dayjs(
                              getValueFromState(state, f.mykey, null)
                            )}
                            onChange={(e) => {
                              // let d = e?.format('YYYY-mm-dd')
                              // d.setTime(d.getTime() + 1000 * 60 * 60 * 3)
                              setState({
                                ...state,
                                [f.mykey]: e?.format("YYYY-MM-DD"),
                              });
                            }}
                            className="form-field__calendar"
                          />
                        </LocalizationProvider>
                      </>
                    )}
                    {f.fieldKind === "number" && (
                      <>
                        <TextField
                          type="number"
                          label={f.label}
                          error={errors[f.mykey] ? errors[f.mykey][0] : ""}
                          disabled={f.mykey?.split(".").length > 1}
                          id={`field-${f.mykey}`}
                          value={getValueFromState(state, f.mykey, 0)}
                          onChange={(e) =>
                            setState({
                              ...state,
                              [f.mykey]: e.target.value,
                            })
                          }
                          className="form-field__input"
                        />
                      </>
                    )}
                    {f.fieldKind === "text" && (
                      <>
                        <TextField
                          // rows={f.rows || 5}
                          value={getValueFromState(state, f.mykey, "")}
                          label={f.label}
                          error={errors[f.mykey] ? errors[f.mykey][0] : ""}
                          disabled={f.disabled || f.mykey.split(".").length > 1}
                          onChange={(e) =>
                            setState({
                              ...state,
                              [f.mykey]: e.target.value,
                            })
                          }
                          className="form-field__input"
                        />
                      </>
                    )}
                    {f.fieldKind === "dict-select" && (
                      <>
                        <AsyncAutocomplete
                          url={f.url}
                          mykey={f.mykey}
                          error={errors[f.mykey] ? errors[f.mykey][0] : ""}
                          state={state}
                          setState={setState}
                          value={getValueFromState(state, f.mykey, null)}
                          label={f.label}
                          nameField={f.nameField}
                          className="form-field__select"
                        />
                      </>
                    )}
                    {firstUpdated && f.fieldKind === "address" && (
                      <>
                        <AddressSuggestions
                          token="5558df51b0171fb2e43f15732601f4aff66e44a8"
                          value={state[f.mykey + "_location"] || ""}
                          defaultQuery={state[f.mykey + "_location"]}
                          onChange={(event) => {
                            setState({
                              ...state,
                              [f.mykey + "_location"]: event?.value || "",
                              [f.mykey +
                              "_coordinates"]: `${event?.data.geo_lat}, ${event?.data.geo_lon}`,
                            });
                          }}
                        />
                        <TextField
                          // rows={f.rows || 5}
                          value={state[f.mykey + "_coordinates"] || ""}
                          label={f.label}
                          error={errors[f.mykey] ? errors[f.mykey][0] : ""}
                          disabled={f.mykey.split(".").length > 1}
                          onChange={(e) =>
                            setState({
                              ...state,
                              [f.mykey + "_coordinates"]: e.target.value || "",
                            })
                          }
                          className="form-field__input"
                        />
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </>
        ))}

      {additionalFields}

      <div className="form__buttons d-flex">
        <button
          type="button"
          className="form__buttons-btn form__buttons-btn--primary d-flex items-center transition"
          onClick={fun}
        >
          Сохранить
        </button>
        <button
          type="button"
          className="form__buttons-btn form__buttons-btn--cancel d-flex items-center transition"
          onClick={() => navigate(-1)}
        >
          Отменить
        </button>
      </div>
    </div>
  );
}
