import SETTINGS from '../settings'

const vehicleEditFields = [
    {
        header: 'Транспортное средство',
        fields: [
            {
                mykey: 'brand',
                fieldKind: 'dict-select',
                label: 'Модель',
                nameField: 'name',
                url: `${SETTINGS.SITE_URL}/api/v1/vehicles/brands/`,
            },
            {
                mykey: 'license_plate',
                fieldKind: 'text',
                label: 'Гос Номер',
                //nameField: 'name',
                // shownNameField: 'full_name',
                //url: `${SETTINGS.SITE_URL}/api/v1/companies/`,
            },
            {
                mykey: 'sts',
                fieldKind: 'text',
                label: 'СТС',
            },
            {
                mykey: 'pts',
                fieldKind: 'text',
                label: 'ПТС',
            },
            {
                mykey: 'max_volume',
                fieldKind: 'number',
                label: 'Макс объем',
            },
            {
                mykey: 'owner',
                fieldKind: 'dict-select',
                label: 'Собственник',
                nameField: 'name',
                url: `${SETTINGS.SITE_URL}/api/v1/companies/`,
            },
            {
                mykey: 'driver',
                fieldKind: 'dict-select',
                label: 'Водитель',
                nameField: 'full_name',
                url: `${SETTINGS.SITE_URL}/api/v1/drivers/`,
            },
            {
                mykey: 'status',
                fieldKind: 'select',
                label: 'Статус',
                nameField: 'name',
                options: [
                    {
                        name: 'Свободен',
                    },
                    {
                        name: 'В работе',
                    },
                    {
                        name: 'В ремонте',
                    },
                ]
            }
        ],
    },
]
export default vehicleEditFields
