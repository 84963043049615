import { FC, PropsWithChildren, createContext, useCallback } from "react";
import { useReqAddForm } from "../../hooks/Reqs/useReqAddForm";
import { ReqForm } from "../../interfaces/req";

interface FormContextType {
  formData: Partial<ReqForm> | null;
  updateFormData: (label: keyof ReqForm) => (value: any) => void;
  snackbarMessage: string;
  handleSubmit: (
    event: React.FormEvent<HTMLFormElement>,
    onOpenModal: () => void
  ) => Promise<void>;
}

export const FormContext = createContext<FormContextType | null>(null);

export const FormProvider: FC<PropsWithChildren> = ({ children }) => {
  const { formData, setFormData, snackbarMessage, handleSubmit } =
    useReqAddForm();

  const updateFormData = useCallback(
    (field: keyof ReqForm) => (value: any) => {
      setFormData((prev) => ({ ...prev, [field]: value } as ReqForm));
    },
    [setFormData]
  );

  return (
    <FormContext.Provider
      value={{ formData, updateFormData, snackbarMessage, handleSubmit }}
    >
      {children}
    </FormContext.Provider>
  );
};
