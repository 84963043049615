import {
  Autocomplete as AutocompleteMui,
  Stack,
  TextField as TextFieldMui,
  styled,
} from "@mui/material";
import { FC } from "react";

type Option = string | { id: number; label: string };

type SelectOutLabel = React.ComponentProps<typeof TextFieldMui> & {
  list: Option[];
  label: string;
  defaultValue?: Option;
  onChangeValue?: (value: string) => void;
  w?: number;
  bg?: string;
};

const Autocomplete = styled(AutocompleteMui)<
  Omit<SelectOutLabel, "list" | "label">
>(({ w, bg }) => ({
  height: "var(--input-height)",
  width: `${w}px`,
  ".MuiFormControl-root": {
    height: "100%",
  },
  ".MuiInputBase-root": {
    height: "100%",
  },
}));
const TextField = styled(TextFieldMui)(({}) => ({
  ".MuiInputBase-root": {
    borderRadius: "10px",
  },
  fieldset: {
    boxShadow: "4px 3px 2px #F6F6F9",
    borderColor: "var(--input-border-color)",
  },
}));

const Label = styled("label")(({}) => ({
  fontSize: "12px",
  fontWeight: 400,
}));

const equalityChack = (option: unknown, value: unknown) => {
  if (typeof option === "string" && typeof value === "string") {
    return option === value;
  }
  if (
    typeof option === "object" &&
    option !== null &&
    typeof value === "object" &&
    value !== null &&
    "id" in option &&
    "id" in value
  ) {
    return option.id === value.id;
  }
  return false;
};

export const SelectOutLabel: FC<SelectOutLabel> = ({
  list,
  w,
  bg,
  defaultValue,
  onChangeValue,
  label,
  ...props
}) => {
  return (
    <Stack gap={1.2}>
      <Label>{label}</Label>
      <Autocomplete
        disablePortal
        options={list}
        defaultValue={defaultValue}
        w={w}
        bg={bg}
        isOptionEqualToValue={equalityChack}
        onInputChange={(_, value) => {
          if (onChangeValue) {
            onChangeValue(value);
          }
        }}
        renderInput={(params) => <TextField {...params} {...props} />}
      />
    </Stack>
  );
};
