import { Company } from "../interfaces/company";
import { ReqForm } from "../interfaces/req";

export const ERROR_MESSAGES = {
  DEFAULT: "Произошла ошибка, данные не были сохранены.",
  NO_CHANGES: "Изменения не были внесены",
  NO_DATE: "Пожалуйста, укажите новую дату",
};

export const VALIDATION_FIELDS = ["address", "vehicle", "driver"];
export const VALIDATION_ADD_FIELDS: (keyof ReqForm)[] = [
  "client",
  "source",
  "req_date",
  "location",
  "container",
];

export const ROUTE_TYPES = ["Поставил - забрал", "Поставил", "Забрал"];

export const SOURCE_REQ = ["Заявка по звонку", "Заявка с сайта"];

export const STATIC_CLIENT_DATA: {
  id: number;
  label: string;
  value: keyof Company;
}[] = [
  { id: 1, label: "Контактное лицо", value: "director" },
  { id: 2, label: "Телефон", value: "phone" },
  { id: 3, label: "Электронная почта", value: "email" },
];

export const SNACKBAR_CONFIG = {
  autoHideDuration: 2000,
  anchorOrigin: { vertical: "top", horizontal: "center" } as const,
};
export const DATE_FORMAT_OPTIONS = {
  day: "2-digit",
  month: "short",
  year: "numeric",
} as const;
