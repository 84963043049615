import { Stack, styled } from "@mui/material";
import { Title } from "../../../../../ui/mui/Title/Title";
import { Group } from "../../../../../ui/mui/Group/Group";
import { useAddFormContext } from "../../../../../hooks/Context/useAddFormContext";
import { SelectOutLabel } from "../../../../../ui/mui/Select/SelectOutLabel";
import { InputOutLabal } from "../../../../../ui/mui/Input/InputOutLabal";
import { useClientSelect } from "../../../../../hooks/Reqs/useClientSelect";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useContainerSelect } from "../../../../../hooks/Reqs/useContainerSelect";

enum RouteType {
  PUT_AND_TAKE = "Поставил - забрал",
  PUT = "Поставил",
  TAKE = "Забрал",
}

type ContainerAction = {
  label: string;
  onChange: (value: string) => void;
  list?: { id: number; label: string }[];
};
type SectionStates = {
  [key in RouteType]: {
    address_label: string;
    container_actions: ContainerAction[];
    isAddress_loading: boolean;
  };
};

const ElementAction = styled("div")(({}) => ({
  width: "150px",
  height: "40px",
  backgroundColor: "#D9D9D9",
  borderRadius: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "14px",
}));

const ContainerAction: React.FC<ContainerAction> = React.memo(
  ({ label, list, onChange }) => {
    return (
      <Stack gap={5}>
        <ElementAction>{label}</ElementAction>
        <SelectOutLabel
          label="Выберите контейнер"
          list={list ?? []}
          w={245}
          placeholder="Введите номер контейнера"
          onChangeValue={onChange}
        />
      </Stack>
    );
  }
);

export const ActionSection = () => {
  const { formData, updateFormData } = useAddFormContext();

  const { addresses_objects } = useClientSelect(formData?.client ?? null);
  const { container_numbers, containers } = useContainerSelect();
  const containersRef = useRef(containers);

  useEffect(() => {
    containersRef.current = containers;
  }, [containers]);

  const handleSelectContainer = useCallback(
    (value: string) => {
      if (!value) return;
      const mod_value = value.split("#")[1]?.split(" ")[0];

      const container_id = containersRef.current?.find(
        (container) => container.num === mod_value
      )?.id;

      updateFormData("container")(container_id);
    },
    [updateFormData]
  );

  const sectionStates: SectionStates = useMemo(
    () => ({
      [RouteType.PUT_AND_TAKE]: {
        address_label:
          "Адрес куда необходимо поставить и откуда забрать контейнер",
        container_actions: [
          {
            label: "Поставить",
            onChange: (value) => handleSelectContainer(value),
          },
          {
            label: "Забрать",
            onChange: (value) => console.log("Забрать:", value),
          },
        ],
        isAddress_loading: true,
      },
      [RouteType.PUT]: {
        address_label: "Адрес куда необходимо поставить  контейнер",
        container_actions: [
          {
            label: "Поставить",
            onChange: (value) => handleSelectContainer(value),
          },
        ],
        isAddress_loading: true,
      },
      [RouteType.TAKE]: {
        address_label: "Адрес откуда необходимо забрать контейнер",
        container_actions: [
          {
            label: "Забрать",
            onChange: (value) => handleSelectContainer(value),
          },
        ],
        isAddress_loading: false,
      },
    }),
    [handleSelectContainer]
  );

  const sectionState = useMemo(() => {
    if (!sectionStates[formData?.kind as RouteType]) {
      console.error(`Неизвестный тип маршрута: ${formData?.kind}`);
      return null;
    }
    return sectionStates[formData?.kind as RouteType];
  }, [formData?.kind]);

  if (!sectionState) return null;

  return (
    <Stack gap={3}>
      <Title variant="h4">Действия по заявке</Title>
      <Group gap={100}>
        <SelectOutLabel
          label={sectionState.address_label}
          list={addresses_objects ?? []}
          w={350}
          placeholder="Выберите адрес компании"
          onChangeValue={(value) => {
            updateFormData("location")(value);
            updateFormData("address_location")(value);
          }}
        />
        <Group gap={70}>
          {sectionState.container_actions.map((action) => (
            <ContainerAction
              key={action.label}
              list={container_numbers}
              {...action}
            />
          ))}
        </Group>
      </Group>
      {sectionState.isAddress_loading && (
        <InputOutLabal
          label="Адрес загрузки контейнера"
          w={350}
          disabled
          developed
        />
      )}
    </Stack>
  );
};
