import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SETTINGS from "../settings";
import { myFetch } from "../utils";
const styles = require('./Layout.css')

export default function Layout() {
    const navigate = useNavigate()
    const meZero: any = {}
    const [me, setMe] = useState(meZero)

    useEffect(() => {
        async function getMe() {
            const res = await myFetch(
                `${SETTINGS.SITE_URL}/api/v1/users/me/`,
                {},
                navigate,
            )
            if (res.status == 200) {
                const me1 = await res.json()
                setMe(me1)
            }
        }
        getMe()
    }, [navigate])

    return (
        <div className="main-content d-flex">
            <Sidebar />
            <div className="content">
                <Outlet context={{me}}/>
            </div>
        </div>
    );
}
