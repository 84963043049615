import { ReqStatus, RouteConfig } from "../interfaces/req";

export const reqStatus: ReqStatus[] = [
  "В работе",
  "Водитель назначен",
  "Завершена",
  "Запланирована",
  "Новая",
  "Отменена",
  "ЧП",
];

export const routeConfig: RouteConfig = {
  "Поставил - забрал": [
    {
      label: "Адрес куда необходимо поставить и откуда забрать контейнер",
      hasContainer: true,
    },
    {
      label: "Адрес загрузки контейнера",
      hasContainer: true,
      disabled: true,
    },
  ],
  Поставил: [
    {
      label: "Адрес куда необходимо поставить контейнер",
      hasContainer: true,
    },
    {
      label: "Адрес загрузки контейнера",
      hasContainer: false,
    },
  ],
  Забрал: [
    {
      label: "Адрес откуда необходимо забрать контейнер",
      hasContainer: true,
    },
  ],
};

export const MODAL_EDIT_CONFIGS = {
  route: {
    title: "Подтвердите смену типа маршрута",
    confirmLabel: "Сменить маршрут",
  },
  date: {
    title: "Подтвердите изменение даты рейса",
    confirmLabel: "Изменить дату",
  },
  refuse: {
    title: "Клиент отказался от заявки",
    confirmLabel: "Отказ от заявки",
  },
  refuse_confirm: {
    title: "Вы уверены что хотите отменить заявку?",
    confirmLabel: "Отказать заявку",
  },
  idleFlight: {
    title: "Зафиксировать холостой рейс?",
    confirmLabel: "Холостой рейс",
  },
  idleFlight_confirm: {
    title: "Вы уверены что хотите отменить заявку?",
    confirmLabel: "Отказать заявку",
  },
  overloading: {
    title: "Зафиксировать перегруз контейнера?",
    confirmLabel: "Перегруз",
  },
  overloading_confirm: {
    title: "Вы уверены что хотите отменить заявку?",
    confirmLabel: "Отказать заявку",
  },
} as const;
