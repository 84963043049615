import { useState, useEffect } from "react";
import { Driver, DriverWithSchedules } from "../../interfaces/driver";
import SETTINGS from "../../settings";
import { exportFileFatch, myFetch } from "../../utils";
import { NavLink, useNavigate, useOutletContext } from "react-router-dom";
import ModalTableFields from "../../components/ModalTableFields";
import { Box, InputAdornment, TextField } from "@mui/material";
import { Select } from "../../components/select/Select";
import { downloadFile } from "../../utils/downloadFile";

const classesSelectSchedule: any = {
  Готов: "is-ready",
  Выходной: "is-weekend",
  Больничный: "is-sick",
  Отгул: "is-time-off",
};

const dowObject: any = {
  1: "Пн",
  2: "Вт",
  3: "Ср",
  4: "Чт",
  5: "Пт",
  6: "Сб",
  0: "Вс",
};

const STATUS_MAP: any = {
  Больничный: "is-sick",
  Готов: "is-ready",
  Выходной: "is-weekend",
  Отгул: "is-time-off",
};

const driverStatusFilterItems = [
  {
    value: "ALL",
    label: "Все",
    aggKey: "count",
  },
  {
    value: "Готов",
    label: "Готов",
    aggKey: "count_ready",
  },
  {
    value: "Выходной",
    label: "Выходной",
    aggKey: "count_weekend",
  },
  {
    value: "Больничный",
    label: "Больничный",
    aggKey: "count_sick",
  },
  {
    value: "Отпуск",
    label: "Отпуск",
    aggKey: "count_vacation",
  },
  {
    value: "Отгул",
    label: "Отгул",
    aggKey: "count_time_off",
  },
];
const driverTableFields = [
  {
    mykey: "status",
    label: "Статус",
  },
  {
    mykey: "phone",
    label: "Телефон",
  },
  {
    mykey: "telegram",
    label: "Телеграм",
  },
  {
    mykey: "car",
    label: "Машина",
  },
  {
    mykey: "complete",
    label: "Выполнено",
  },
  {
    mykey: "on_line",
    label: "Выходы на линию",
  },
  {
    mykey: "secret",
    label: "СЕКРЕТ",
  },
];

export default function DriverList() {
  const ds1: Driver[] = [];
  const navigate = useNavigate();
  const [drivers, setDrivers] = useState(ds1);
  const ds2: DriverWithSchedules[] = [];
  const [query, setQuery] = useState("");

  const { me }: { me: any } = useOutletContext();

  const [driversWithSchedules, setDriversWithSchedules] = useState(ds2);
  const [isWithSchedules, setIsWithSchedules] = useState(false);
  const baseCounts: any = {
    count_ready: 0,
    count_weekend: 0,
    count_sick: 0,
    count_vacation: 0,
    count_time_off: 0,
    count: 0,
  };
  const [counts, setCounts] = useState(baseCounts);
  const [currentStatus, setCurrentStatus] = useState("ALL");
  const sch1: any = {};
  const [schedules, setSchedules] = useState(sch1);
  const dheaders: string[] = [];
  const [dateHeaders, setDateHeaders] = useState(dheaders);
  const domh: string[] = [];
  const [domHeaders, setDomHeaders] = useState(domh);
  const [verticalIndex, setVerticalIndex] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openModal = (): void => setIsModalOpen(true);
  const closeModal = (): void => setIsModalOpen(false);

  const tableFieldsString = localStorage.getItem("driverTableFields");
  let tableFieldsObj;
  if (tableFieldsString) {
    tableFieldsObj = JSON.parse(tableFieldsString);
  } else {
    tableFieldsObj = {};
  }
  const [tableFieldIsClosed, setTableFieldIsClosed]: [
    any,
    (state: any) => void
  ] = useState(tableFieldsObj);

  useEffect(() => {
    async function fetchData() {
      const res = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/drivers/?query=${query}&status=${currentStatus}`,
        {},
        navigate
      );
      const _json = await res.json();
      const {
        results,
        count,
        count_weekend,
        count_sick,
        count_vacation,
        count_time_off,
        count_ready,
      } = _json;
      const ds: Driver[] = results;
      setDrivers(ds);
      setCounts({
        count,
        count_weekend,
        count_sick,
        count_vacation,
        count_time_off,
        count_ready,
      });
    }
    fetchData();
  }, [navigate, currentStatus, isWithSchedules, query]);

  const handleExportFile = async () => {
    if (driversWithSchedules.length > 0) {
      const params = {
        page: "",
      };
      const path = location.pathname.replace(/\/+/g, "");
      console.log(path);
      const response = await exportFileFatch(
        path,
        navigate,
        params,
        "with-schedules"
      );
      try {
        await downloadFile(response, "with-schedules");
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      const params = {
        status: currentStatus,
      };
      const path = location.pathname.replace(/\/+/g, "");
      const response = await exportFileFatch(path, navigate, params);
      try {
        await downloadFile(response, path);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleChangeQuery = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setQuery(event.target.value);
  };

  return (
    <>
      <div className="drivers-page">
        <ModalTableFields
          closeModal={closeModal}
          isModalOpen={isModalOpen}
          state={tableFieldIsClosed}
          setState={setTableFieldIsClosed}
          tableFields={driverTableFields}
        />
        <div className="page-top d-flex items-center">
          <div className="page-top__left d-flex items-center">
            <span className="page-top__title">{`Водители · ${counts.count}`}</span>
            <span
              className="page-top__left-settings icon-setting transition"
              onClick={openModal}
            ></span>
          </div>
          <div className="page-top__right d-flex items-center">
            <div className="page-top__calendar d-flex items-center">
              <div className="page-top__calendar-icon circle-center">
                <span className="icon-calendar"></span>
              </div>
              {/*<>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => {
                                    setDateKind('by_date')
                                    setStartDate(date!)
                                }}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd.MM.YYYY"
                                shouldCloseOnSelect
                                // showYearPicker
                            />
                            &nbsp;-&nbsp;
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => {
                                    setEndDate(date!)
                                    setDateKind('by_date')
                                }}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd.MM.YYYY"
                                shouldCloseOnSelect
                                // showYearPicker
                            />
                        </>*/}
            </div>

            <div className="page-top__search">
              <TextField
                size="small"
                variant="outlined"
                value={query}
                onChange={handleChangeQuery}
                placeholder="Поиск имени водителя..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="icon-search"></span>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="page-top__dispatcher d-flex items-center">
              <div className="page-top__dispatcher-icon circle-center">
                <span className="icon-settings-b"></span>
              </div>

              <span className="page-top__dispatcher-text d-flex items-center">
                Диспетчер · <span className="transition">{me?.full_name}</span>{" "}
                <span className="icon-angle-down transition"></span>
              </span>

              <div className="dispatcher-menu">
                <div className="dispatcher-menu__inner">
                  <a href="" className="dispatcher-menu__item transition">
                    Личные данные
                  </a>
                  <a href="" className="dispatcher-menu__item transition">
                    Сменить роль
                  </a>
                  <a href="" className="dispatcher-menu__item transition">
                    Выйти
                  </a>
                </div>
              </div>
            </div>

            <NavLink
              to={"/drivers/add/"}
              className="page-top__button d-flex items-center transition"
            >
              Добавить водителя
            </NavLink>
          </div>
        </div>
        <div className="page-content">
          <div className="drivers-page__top d-flex items-center justify-between pr-10">
            <div className="drivers-page__filter d-flex">
              {driverStatusFilterItems.map(
                (item: { value: string; label: string; aggKey: string }) => {
                  const classList =
                    "drivers-page__filter-item d-flex items-center transition" +
                    (item.value === currentStatus ? " is-active" : "");
                  return (
                    <div
                      key={item.value}
                      className={classList}
                      onClick={() => {
                        setCurrentStatus(item.value);
                        setDriversWithSchedules([]);
                        // setIsWithSchedules(false)
                      }}
                    >{`${item.label} · ${counts[item.aggKey]}`}</div>
                  );
                }
              )}
              <div
                className="drivers-page__filter-item d-flex items-center transition"
                onClick={() => {
                  async function fetchData() {
                    const res = await myFetch(
                      `${SETTINGS.SITE_URL}/api/v1/drivers/with-schedules/`,
                      {},
                      navigate
                    );
                    const _json = await res.json();
                    const { results } = _json;
                    const ds: DriverWithSchedules[] = results;
                    setDriversWithSchedules(ds);
                    if (ds.length > 0) {
                      const dheaders: string[] = [];
                      const domHeaders2: string[] = [];
                      const startDate = new Date();
                      const finishDate = new Date();
                      finishDate.setTime(
                        startDate.getTime() + 30 * 24 * 3600 * 1000
                      );
                      let current = startDate;
                      let i = 0;
                      while (current <= finishDate) {
                        if (i >= ds[0].schedules.length) {
                          break;
                        }
                        if (i == 0 || current.getDate() == 1) {
                          dheaders.push(
                            `${current.getMonth()}/${current.getDate()}`
                          );
                        } else {
                          dheaders.push(current.getDate().toString());
                        }
                        domHeaders2.push(dowObject[current.getDay()]);
                        current.setTime(current.getTime() + 24 * 3600 * 1000);
                        i += 1;
                      }
                      setDateHeaders(dheaders);
                      setDomHeaders(domHeaders);
                    }
                    setDrivers([]);
                    // setIsWithSchedules(true)
                  }
                  fetchData();
                }}
              >
                График работы
              </div>
            </div>
            <Box sx={{ width: "101px" }}>
              <Select
                onChange={handleExportFile}
                itemList={["Экспорт в xlsx"]}
                value={""}
                label={"Действия"}
              />
            </Box>
          </div>
          {driversWithSchedules.length == 0 && (
            <div className="drivers-page__table">
              <table className="drivers-table table">
                <thead className="table__top">
                  <tr className="table__top-tr">
                    <th className="table__top-th th-id">
                      <div className="d-flex items-center">
                        <label className="table__checkbox">
                          <input
                            type="checkbox"
                            className="table__checkbox-input"
                          />
                          <div className="table__checkbox-block d-flex block-center transition">
                            <span className="icon-check"></span>
                          </div>
                        </label>
                        Id
                      </div>
                    </th>
                    {!tableFieldIsClosed.status && (
                      <th className="table__top-th th-status">Статус</th>
                    )}
                    <th className="table__top-th th-name">ФИО</th>
                    {!tableFieldIsClosed.phone && (
                      <th className="table__top-th th-phone">Номер телефона</th>
                    )}
                    {!tableFieldIsClosed.telegram && (
                      <th className="table__top-th th-telegram">Телеграм id</th>
                    )}
                    {!tableFieldIsClosed.car && (
                      <th className="table__top-th th-car">Автомашина</th>
                    )}
                    {!tableFieldIsClosed.complete && (
                      <th className="table__top-th th-complete">Выполнено</th>
                    )}
                    {!tableFieldIsClosed.on_line && (
                      <th className="table__top-th th-on_line">
                        Выходы на линию
                      </th>
                    )}
                    {!tableFieldIsClosed.secret && (
                      <th className="table__top-th th-secret">SECRET</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {drivers?.map((driver) => {
                    return (
                      <tr className="table__row" key={driver.id}>
                        <td className="table__td td-id">
                          <div className="table__td-inner d-flex items-center">
                            <label className="table__checkbox">
                              <input
                                type="checkbox"
                                className="table__checkbox-input"
                              />
                              <div className="table__checkbox-block d-flex block-center transition">
                                <span className="icon-check"></span>
                              </div>
                            </label>
                            {driver.id}
                          </div>
                        </td>
                        {!tableFieldIsClosed.status && (
                          <td className="table__td td-status">
                            <div className="table__td-inner d-flex items-center">
                              <div
                                className={`td-status__block ${
                                  STATUS_MAP[driver.status] || "is-time-off"
                                } d-flex items-center`}
                              >
                                {/* classes for statuses: at-client - У клиента, on-base - На базе */}
                                {driver.status}
                              </div>
                            </div>
                          </td>
                        )}

                        <td className="table__td td-name">
                          <div className="table__td-inner d-flex items-center">
                            {`${driver.last_name} ${driver.first_name} ${driver.second_name}`}
                          </div>
                        </td>
                        {!tableFieldIsClosed.phone && (
                          <td className="table__td td-phone">
                            <div className="table__td-inner d-flex items-center">
                              {driver.phone}
                            </div>
                          </td>
                        )}
                        {!tableFieldIsClosed.telegram && (
                          <td className="table__td td-telegram">
                            <div className="table__td-inner d-flex items-center">
                              <div className="td-telegram__block d-flex items-center">
                                <span className="icon-telegram"></span>
                                {driver.telegram}
                              </div>
                            </div>
                          </td>
                        )}
                        {!tableFieldIsClosed.car && (
                          <td className="table__td td-car">
                            <div className="table__td-inner d-flex items-center">
                              {driver.current_vehicle?.license_plate}
                            </div>
                          </td>
                        )}
                        {!tableFieldIsClosed.complete && (
                          <td className="table__td td-complete">
                            <div className="table__td-inner d-flex items-center">
                              {driver.finished_reqs_count}
                            </div>
                          </td>
                        )}
                        {!tableFieldIsClosed.on_line && (
                          <td className="table__td td-on_line">
                            <div className="table__td-inner d-flex items-center">
                              {driver.on_line}
                            </div>
                          </td>
                        )}
                        {!tableFieldIsClosed.secret && (
                          <td className="table__td td-secret">
                            <div className="table__td-inner d-flex items-center">
                              <a
                                href={`/drivers/instructions/${driver.page_secret}`}
                              >
                                SECRET
                              </a>
                            </div>
                          </td>
                        )}
                        <a
                          href={`/drivers/${driver.id}/edit/`}
                          className="table__edit circle-center transition"
                        >
                          <span className="icon-edit"></span>
                        </a>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          {driversWithSchedules.length > 0 && (
            <div className="drivers-page__table">
              <table className="schedule-table table">
                <thead className="table__top">
                  <tr className="table__top-tr">
                    <th className="table__top-th th-name">Сотрудник</th>
                    {dateHeaders.map((dh) => (
                      <th className="table__top-th th-date">{dh}</th>
                    ))}
                    <th className="table__top-th th-hours">Часов</th>
                    <th className="table__top-th th-shift">Смены</th>
                  </tr>
                  <tr className="table__top-tr tr-days_of_week">
                    <th className="table__top-th th-name"></th>
                    {domHeaders.map((dom) => (
                      <th className="table__top-th th-date">{dom}</th>
                    ))}
                    <th className="table__top-th th-hours">&nbsp;</th>
                    <th className="table__top-th th-shift">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {driversWithSchedules?.map((driver) => {
                    if (!driver.schedules) {
                      return <></>;
                    }
                    return (
                      <tr className="table__row" key={driver.id}>
                        <td className="table__td td-name">
                          <div className="table__td-inner d-flex items-center">
                            {`${driver.last_name} ${driver.first_name} ${driver.second_name}`}
                          </div>
                        </td>
                        {driver.schedules?.map((sch, index) => {
                          return (
                            <td
                              onMouseEnter={() => setVerticalIndex(index)}
                              className="table__td td-date"
                            >
                              <div className="table__td-inner d-flex items-center">
                                <select
                                  className={
                                    classesSelectSchedule[
                                      schedules[sch[1]] || sch[0]
                                    ] +
                                    (verticalIndex === index
                                      ? " is-vertical-colored"
                                      : "")
                                  }
                                  value={schedules[sch[1]] || sch[0]}
                                  onChange={(event) => {
                                    const val = event.target.value;
                                    async function fetchData() {
                                      const res = await myFetch(
                                        `${SETTINGS.SITE_URL}/api/v1/drivers/schedules/${sch[1]}/`,
                                        {
                                          method: "PUT",
                                          body: JSON.stringify({
                                            id: sch[1],
                                            status: val,
                                          }),
                                        },
                                        navigate
                                      );
                                      if (res.status < 300) {
                                        setSchedules({
                                          ...schedules,
                                          [sch[1]]: val,
                                        });
                                      }
                                    }
                                    fetchData();
                                  }}
                                >
                                  <option value="Готов">Г</option>
                                  <option value="Больничный">Б</option>
                                  <option value="Отгул">О</option>
                                  <option value="Выходной">В</option>
                                </select>
                              </div>
                            </td>
                          );
                        })}
                        <td className="table__td td-hours">
                          <div className="table__td-inner d-flex items-center">
                            +38
                          </div>
                        </td>
                        <td className="table__td td-shift">
                          <div className="table__td-inner d-flex items-center">
                            +4
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
