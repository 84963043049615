import { FC } from "react";
import { Req } from "../../../interfaces/req";
import { getDateFormat } from "../../../utils/getDateFormat";

type ViewReqsProps = {
  reqs: Req[] | null;
};

export const ViewReqs: FC<ViewReqsProps> = ({ reqs }) => {
  return (
    <div className="history-list">
      {reqs?.map((req) => (
        <div className="history-item d-flex justify-between" key={req.id}>
          <div className="history-item__column d-flex flex-column">
            <span className="history-item__column-text">
              <span>#{req.id}</span> ·{" "}
              {getDateFormat(req.req_date, {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })}
            </span>
            <span className="history-item__column-title">{req.location}</span>
          </div>

          <div className="history-item__column d-flex flex-column">
            <span className="history-item__column-text">Автомашина</span>
            <span className="history-item__column-driver d-flex items-center">
              {req.vehicle?.license_plate}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
