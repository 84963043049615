import { useCallback, useMemo, useState } from "react";
import { ReqForm } from "../../interfaces/req";
import { myFetch } from "../../utils";
import SETTINGS from "../../settings";
import { useNavigate } from "react-router-dom";
import {
  ERROR_MESSAGES,
  VALIDATION_ADD_FIELDS,
  VALIDATION_FIELDS,
} from "../../constants/constants";

const initialFormData: Partial<ReqForm> = {
  kind: "Поставил - забрал",
  client: null,
  source: null,
  container: null,
  location: null,
  req_date: null,
  total_sum: 0,
  address_coordinates: "55.831232, 49.140286",
  address_location: null,
};

const warning_value: { [key in keyof ReqForm]: string } = {
  client: "Клиента",
  source: "Источника",
  kind: "Тип маршрута",
  container: "Контейнер",
  location: "Адрес",
  req_date: "Дату рейса",
  total_sum: "Сумму оплаты",
  address_coordinates: "Координаты адреса",
  address_location: "Адрес-локация",
};

export const useReqAddForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] =
    useState<Partial<ReqForm | null>>(initialFormData);
  const [snackbarMessage, setSnackBarMessage] = useState(
    ERROR_MESSAGES.DEFAULT
  );

  const handleSubmit = useCallback(
    async (
      event: React.FormEvent<HTMLFormElement>,
      onOpenModal: () => void
    ) => {
      event.preventDefault();

      const requiredFields: (keyof ReqForm)[] = VALIDATION_ADD_FIELDS;
      const missingFields = requiredFields.filter((field) =>
        formData ? !formData[field] : field
      );

      if (missingFields.length > 0) {
        const warnings = missingFields
          .map((field) => warning_value[field])
          .join(", ");
        setSnackBarMessage(`Пожалуйста, заполните данные: ${warnings}`);
        onOpenModal();
        return;
      }

      if (formData) {
        const res = await myFetch(
          `${SETTINGS.SITE_URL}/api/v1/reqs/`,
          {
            method: "POST",
            body: JSON.stringify(formData),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          },
          navigate
        );
        if (res.ok) {
          navigate("/reqs");
        } else {
          onOpenModal();
          const jsonData = await res.json();
          for (let msg of VALIDATION_FIELDS) {
            if (jsonData[msg]) {
              setSnackBarMessage(`${msg} - ${jsonData[msg]}`);
              break;
            }
          }
        }
      } else {
        setSnackBarMessage("Изменения не были внесены");
        onOpenModal();
      }
    },
    [formData, navigate]
  );

  return useMemo(
    () => ({ formData, setFormData, snackbarMessage, handleSubmit }),
    [formData, snackbarMessage, handleSubmit]
  );
};
