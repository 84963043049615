import { Badge, FormControl, MenuItem, TextField, styled } from "@mui/material";
import { FC } from "react";

type SelectFormProps = React.ComponentProps<typeof TextField> & {
  list: (string | { id: number; label: string })[] | null;
  w?: number;
  bg?: string;
  developed?: boolean;
};

const SelectMui = styled(TextField)<Pick<SelectFormProps, "w" | "bg">>(
  ({ w, bg }) => ({
    width: `${w}px`,
    height: "var(--input-height)",
    backgroundColor: bg,
    borderRadius: "10px",

    ".MuiFormLabel-root": {
      fontSize: "var(--input-label-font-size)",
      color: "#B2B3C3",
      lineHeight: "12px",
      fontWeight: 400,
      top: "16px",
    },
    ".MuiFormLabel-root[data-shrink='false']": {
      top: 0,
    },
    ".MuiInputBase-root": {
      height: "100%",

      ".MuiInputBase-input": {
        fontSize: "var(--input-font-size)",
        marginTop: "14px",
      },
      fieldset: {
        borderColor: "var(--input-border-color)",
        borderRadius: "10px",
        height: "100%",
        top: 0,
        boxShadow: "4px 3px 2px #F6F6F9",
        legend: {
          display: "none",
        },
      },
    },
  })
);

const getMenuItem = (
  item: string | { id: number; label: string },
  index: number
) => {
  if (typeof item === "string") {
    return (
      <MenuItem key={index} value={item}>
        {item}
      </MenuItem>
    );
  } else {
    return (
      <MenuItem key={item.id} value={item.id}>
        {item.label}
      </MenuItem>
    );
  }
};
export const SelectForm: FC<SelectFormProps> = ({
  list,
  w,
  bg,
  developed = false,
  ...props
}) => {
  return (
    <Badge badgeContent={"в работе"} color="warning" invisible={!developed}>
      <FormControl>
        <SelectMui w={w} bg={bg} {...props} select>
          {list?.map((item, i) => getMenuItem(item, i))}
        </SelectMui>
      </FormControl>
    </Badge>
  );
};
