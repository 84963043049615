import { TextField, styled } from "@mui/material";
import { FC } from "react";

type InputFormProps = React.ComponentProps<typeof TextField> & {
  w?: number;
};

const Input = styled(TextField)<InputFormProps>(({ w }) => ({
  width: `${w}px`,
  height: "var(--input-height)",

  ".MuiFormLabel-root": {
    fontSize: "var(--input-label-font-size)",
    color: "#B2B3C3",
    lineHeight: "12px",
    fontWeight: 400,
    top: "16px",
  },
  ".MuiFormLabel-root[data-shrink='false']": {
    top: 0,
  },
  ".MuiInputBase-root": {
    height: "100%",

    ".MuiInputBase-input": {
      fontSize: "var(--input-font-size)",
      marginTop: "14px",
    },
    fieldset: {
      borderColor: "var(--input-border-color)",
      borderRadius: "10px",
      height: "100%",
      top: 0,
      boxShadow: "4px 3px 2px #F6F6F9",
      legend: {
        display: "none",
      },
    },
  },
  ".MuiInputBase-root.Mui-disabled": {
    backgroundColor: "var(--input-disabled-color)",
    borderRadius: "10px",
  },
  ".Mui-disabled": {
    fieldset: {
      borderWidth: "0px",
    },
  },
}));

export const InputForm: FC<InputFormProps> = ({ w, ...props }) => {
  return <Input w={w} {...props} />;
};
