import { TextField, Autocomplete } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useState, SyntheticEvent } from "react"
import { myFetch } from "../utils"

export default function AsyncAutocomplete(
    {
        url,
        value,
        label,
        error,
        nameField,
        setState,
        state,
        mykey,
        className,
    }: {
        mykey: string
        state: any
        url: string
        value: any
        setState: Function
        label: string
        nameField: string
        error: string | undefined
        className: string
    }) {
    const navigate = useNavigate()
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleInputChange = async (event: SyntheticEvent<Element, Event>, newInputValue: string) => {
        setLoading(true);
        try {
            const response = await myFetch(
                `${url}?query=${newInputValue}`,
                {},
                navigate,
            );
            const data = await response.json();
            setOptions(data.results || []);
        } catch (error) {
            console.error('Ошибка при запросе:', error);
        }
        setLoading(false);
    };

    return (
        <Autocomplete
            getOptionKey={(o: any) => o.id}
            getOptionLabel={(o: any) => o[nameField]}
            options={options}
            // error={error}
            loading={loading}
            onChange={(e, v) => {
                // console.log(key, v)
                setState({ ...state, [mykey]: v })
            }}
            value={value || null}
            isOptionEqualToValue={(o: any, v: any) => o.id === v.id}
            onInputChange={handleInputChange}
            renderInput={(params) => {
                return <TextField error={error ? true : false} helperText={error} {...params} label={label} variant="outlined" />
            }}
            className={className}
        />
    );
}
