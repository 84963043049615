import { Badge, Typography, styled } from "@mui/material";
import { FC, PropsWithChildren } from "react";

type Title = React.ComponentProps<typeof Typography> & {
  developed?: boolean;
};

const TitleElement = styled(Typography)<Title>(({}) => ({
  fontSize: "18px",
  fontWeight: 700,
}));

export const Title: FC<PropsWithChildren & Title> = ({
  children,
  developed = false,
  ...props
}) => {
  return (
    <TitleElement {...props}>
      <Badge badgeContent={"в работе"} color="warning" invisible={!developed}>
        {children}
      </Badge>
    </TitleElement>
  );
};
