import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as SelectMui,
  SelectChangeEvent,
} from "@mui/material";
import { FC } from "react";
import { AllContainersStatuses } from "../../pages/MapWithReqs";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type SelectProps = {
  onChange: (event: SelectChangeEvent) => void;
  itemList: string[];
  value: string;
  label: string;
};

export const Select: FC<SelectProps> = ({
  itemList,
  onChange,
  value,
  label,
}) => {
  return (
    <FormControl
      sx={{ m: 1, minWidth: 101, height: "24px", margin: 0, width: "inherit" }}
      size="small"
    >
      <InputLabel id="status" sx={{ fontSize: "10px" }}>
        {label}
      </InputLabel>
      <SelectMui
        sx={{ borderRadius: label === "Действия" ? "12px !important" : "6px" }}
        className="select"
        labelId="status"
        value={value}
        onChange={onChange}
        IconComponent={KeyboardArrowDownIcon}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {itemList.map((item, i) => (
          <MenuItem value={item} key={i}>
            {item} {label === "Статус" || "Действия" ? "" : "кубов"}
          </MenuItem>
        ))}
      </SelectMui>
    </FormControl>
  );
};
