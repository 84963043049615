import { Container } from "../../interfaces/container";

export const BallonContentContainer = (container: Container) => {
  const { status, id, volume, num } = container;

  return `
        <div class="balloon__body">
            <div class="balloon__header">
                <div class="balloon__header-status">${status}</div>
                <div class="balloon__header-reqid">${id}</div>
            </div>
            <div class="balloon__content">
               
                <div class="balloon__content-address">${volume} куб.</div>
            </div>
            <div class="balloon__footer">
                <div class="balloon__footer-container-id">Контейнер <span className="balloon__footer-container-id_bold">#${num}</span></div>
            </div>
        </div>
    `;
};
