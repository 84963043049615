import { useCallback, useMemo, useState } from "react";

interface ModalState {
  [key: string]: boolean;
}
const defaultValue: ModalState = {
  route: false,
  date: false,
  date_confirm: false,
  refuse: false,
  refuse_confirm: false,
  idleFlight: false,
  idleFlight_confirm: false,
  overloading: false,
  overloading_confirm: false,
};

export const useConfirmModals = () => {
  const [modalStates, setModalStates] = useState<ModalState>(defaultValue);

  const toggleModal = useCallback((modalName: string) => {
    setModalStates((prev) => ({ ...prev, [modalName]: !prev[modalName] }));
  }, []);

  return useMemo(
    () => ({ modalStates, toggleModal }),
    [modalStates, toggleModal]
  );
};
