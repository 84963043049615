import { Req } from "../../interfaces/req";

export const BallonContent = (request: Req) => {
  const { status, id, client, address, container } = request;

  return `
        <div class="balloon__body">
            <div class="balloon__header">
                <div class="balloon__header-status">${status}</div>
                <div class="balloon__header-reqid">${id}</div>
            </div>
            <div class="balloon__content">
                <div class="balloon__content-client">${client?.name ?? ""}</div>
                <div class="balloon__content-address">${
                  address?.location ?? ""
                }</div>
            </div>
            <div class="balloon__footer">
                <div class="balloon__footer-container-id">Контейнер <span className="balloon__footer-container-id_bold">${
                  container?.num ?? "Н/Д"
                }</span></div>
            </div>
        </div>
    `;
};
