import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContainerStat } from "../../interfaces/container";
import SETTINGS from "../../settings";
import { myFetch } from "../../utils";

export const useContainerSelect = () => {
  const navigate = useNavigate();
  const [containers, setContainers] = useState<ContainerStat[] | null>(null);

  useEffect(() => {
    let isMounted = true;
    const fetchCompany = async () => {
      const response = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/containers/`,
        {},
        navigate
      );
      if (response.ok) {
        const jsonData = await response.json();
        setContainers(jsonData.results);
      } else {
        setContainers(null);
        return;
      }
    };
    fetchCompany();

    return () => {
      isMounted = false;
    };
  }, [navigate]);

  const container_numbers = useMemo(() => {
    return (
      containers?.map((container) => ({
        id: +container.id,
        label: `#${container.num} (тип - ${container.kind})`,
      })) || []
    );
  }, [containers]);

  return useMemo(
    () => ({ container_numbers, containers }),
    [container_numbers, containers]
  );
};
