const cEditFields = [
    {
        header: "Объем контейнера",
        fields: [
            {
                mykey: 'volume',
                fieldKind: 'number',
                label: 'Объем',
            },
            {
                mykey: 'length',
                fieldKind: 'number',
                label: 'Длина',
            },
            {
                mykey: 'width',
                fieldKind: 'number',
                label: 'Ширина',
            },
            {
                mykey: 'height',
                fieldKind: 'number',
                label: 'Высота',
            },
        ]
    },
    {
        header: "Дополнительная информация",
        fields: [
            {
                mykey: 'created_by.full_name',
                fieldKind: 'text',
                label: 'Контейнер добавлен',
            },
            {
                mykey: 'created_at',
                fieldKind: 'text',
                label: 'Дата добавления',
                disabled: true,
            },
            {
                mykey: 'comment',
                fieldKind: 'text',
                label: 'Комментарий',
            },
        ],
    },
]

export default cEditFields