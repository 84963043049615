import { Stack } from "@mui/material";
import { Title } from "../../../../../ui/mui/Title/Title";
import { InputForm } from "../../../../../ui/mui/Input/Input";
import { useAddFormContext } from "../../../../../hooks/Context/useAddFormContext";

export const CommentSection = () => {
  const { updateFormData } = useAddFormContext();
  return (
    <Stack gap={3}>
      <Title variant="h4">Комментарий</Title>
      <InputForm
        placeholder="Оставьте свой комментарий..."
        w={700}
        onChange={(event) => updateFormData("comment")(event.target.value)}
      />
    </Stack>
  );
};
