import { FC } from "react";
import { ModalConfirm } from "./ModalConfirm";
import { FormConfirmModals } from "../../../interfaces/req";

export const EditConfirmModals: FC<FormConfirmModals> = ({
  configs,
  states,
  handlers,
}) => {
  return (
    <>
      {handlers.route && (
        <ModalConfirm
          config={{
            ...configs.route,
            opened: states.route ?? false,
            onConfirm: handlers.route.onConfirm,
            close: handlers.route.onClose,
          }}
        />
      )}
      {handlers.date && (
        <ModalConfirm
          config={{
            ...configs.date,
            opened: states.date ?? false,
            onConfirm: handlers.date.onConfirm,
            close: handlers.date.onClose,
          }}
        />
      )}
      {handlers.refuse && (
        <ModalConfirm
          config={{
            ...configs.refuse,
            opened: states.refuse ?? false,
            onConfirm: handlers.refuse.onConfirm,
            close: handlers.refuse.onClose,
          }}
        />
      )}
      {handlers.refuse_confirm && (
        <ModalConfirm
          config={{
            ...configs.refuse_confirm,
            opened: states.refuse_confirm ?? false,
            onConfirm: handlers.refuse_confirm.onConfirm,
            close: handlers.refuse_confirm.onClose,
          }}
        />
      )}
      {handlers.idleFlight && (
        <ModalConfirm
          config={{
            ...configs.idleFlight,
            opened: states.idleFlight ?? false,
            onConfirm: handlers.idleFlight.onConfirm,
            close: handlers.idleFlight.onClose,
          }}
        />
      )}
      {handlers.idleFlight_confirm && (
        <ModalConfirm
          config={{
            ...configs.idleFlight_confirm,
            opened: states.idleFlight_confirm ?? false,
            onConfirm: handlers.idleFlight_confirm.onConfirm,
            close: handlers.idleFlight_confirm.onClose,
          }}
        />
      )}
      {handlers.overloading && (
        <ModalConfirm
          config={{
            ...configs.overloading,
            opened: states.overloading ?? false,
            onConfirm: handlers.overloading.onConfirm,
            close: handlers.overloading.onClose,
          }}
        />
      )}
      {handlers.overloading_confirm && (
        <ModalConfirm
          config={{
            ...configs.overloading_confirm,
            opened: states.overloading_confirm ?? false,
            onConfirm: handlers.overloading_confirm.onConfirm,
            close: handlers.overloading_confirm.onClose,
          }}
        />
      )}
    </>
  );
};
