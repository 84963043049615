import { useEffect, useState, SyntheticEvent, useMemo } from "react";
import { TextField, Autocomplete, Snackbar } from "@mui/material";
import SETTINGS from "../../settings";
import MyForm from "../../components/MyForm";
import { myFetch } from "../../utils";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import reqEditFields from "../../form-fields/req-edit";
import { ModalMui } from "../../components/Modal";
import { useDisclosure } from "../../hooks/useDisclosure";
import { RescheduleDate } from "../../components/req/reschedule-date/RescheduleDate";
import { EditReqForm } from "../../components/req/form/edit-form/EditForm";
import { FormConfirmModals, Req } from "../../interfaces/req";
import { getDateFormat } from "../../utils/getDateFormat";
import { getTime } from "../../utils/getTime";
import { ModalConfirm } from "../../components/modals/modal-confirm/ModalConfirm";
import { EditConfirmModals } from "../../components/modals/modal-confirm/EditConfirmModals";
import { MODAL_EDIT_CONFIGS } from "../../params/req";
import {
  DATE_FORMAT_OPTIONS,
  SNACKBAR_CONFIG,
} from "../../constants/constants";
import { useConfirmModals } from "../../hooks/Modals/useConfirmModals";

const historyDataMappings: { [key: string]: { title: string; class: string } } =
  {
    kind: { title: "Изменения типа маршрута", class: "history-item--orange" },
    total_sum: {
      title: "Изменение суммы оплаты",
      class: "history-item--orange",
    },
    driver: { title: "Назначение водителя", class: "history-item--orange" },
    container: { title: "Изменения контейнера", class: "history-item--orange" },
    req_date: { title: "Изменения даты заявки", class: "history-item--orange" },
    status: { title: "Отменена", class: "history-item--orange" },
  };
// const ReqAdditionalFields = ({
//   reqId,
//   req,
//   setReq,
//   addAs,
//   setAddAs,
//   aserv,
//   setAserv,
//   errors,
//   setErrors,
// }: {
//   reqId: string | undefined;
//   req: any;
//   setReq: Function;
//   addAs: boolean;
//   aserv: any;
//   setAserv: Function;
//   setAddAs: Function;
//   errors: any;
//   setErrors: Function;
// }) => {
//   const baseOptions: any = { test: [] };
//   const [options, setOptions] = useState(baseOptions);
//   const baseLoading: any = {};
//   const [loading, setLoading] = useState(baseLoading);
//   const baseState: any = {};
//   const [state, setState] = useState(baseState);
//   const navigate = useNavigate();

//   const handleInputChange = (index: string) => {
//     return async (
//       event: SyntheticEvent<Element, Event>,
//       newInputValue: string
//     ) => {
//       setLoading(true);
//       try {
//         const response = await myFetch(
//           `${SETTINGS.SITE_URL}/api/v1/reqs/services/`,
//           {},
//           navigate
//         );
//         const data = await response.json();
//         setOptions({ ...options, [index]: data.results || [] });
//       } catch (error) {
//         console.error("Ошибка при запросе:", error);
//       }
//       setLoading(false);
//     };
//   };

//   return (
//     <>
//       <div className="form d-flex flex-wrap">
//         <div className="form__title">Дополнительные услуги</div>
//         {req.additional_services.map((as1: any, index: number) => {
//           return (
//             <div
//               className="form__row"
//               style={{
//                 border: as1.to_delete
//                   ? as1.id
//                     ? "1px solid red"
//                     : "1px solid orange"
//                   : "",
//               }}
//             >
//               <div className="form__row-inner d-flex">
//                 <div className="form__row-field">
//                   <Autocomplete
//                     getOptionKey={(o: any) => o.id}
//                     getOptionLabel={(o: any) => o.name}
//                     options={options[index] || []}
//                     // error={error}
//                     loading={loading}
//                     onChange={(e, v) => {
//                       // console.log(key, v)
//                       as1.service = v;
//                       setState(JSON.parse(JSON.stringify(state)));
//                     }}
//                     value={as1.service || null}
//                     isOptionEqualToValue={(o: any, v: any) => o.id === v.id}
//                     onInputChange={handleInputChange(index.toString())}
//                     renderInput={(params) => {
//                       return (
//                         <TextField
//                           error={
//                             errors?.additional_services?.[index]?.[0] || null
//                           }
//                           helperText={
//                             errors?.additional_services?.[index]?.[0] || null
//                           }
//                           {...params}
//                           label="Вид услуги"
//                           variant="outlined"
//                         />
//                       );
//                     }}
//                     // className={className}
//                   />
//                 </div>
//                 <div className="form__row-field">
//                   <TextField
//                     value={as1.cost}
//                     label=""
//                     error={errors?.bank_accounts?.[index]?.account?.[0] || null}
//                     onChange={(e) => {
//                       as1.cost = e.target.value;
//                       setReq({
//                         ...req,
//                         additional_services: JSON.parse(
//                           JSON.stringify(req.additional_services)
//                         ),
//                       });
//                     }}
//                     className="form__row-input"
//                   />
//                 </div>
//                 <div
//                   className="form__row-action d-flex block-center"
//                   onClick={() => {
//                     as1.to_delete = !as1.to_delete;
//                     setReq({
//                       ...req,
//                       additional_services: JSON.parse(
//                         JSON.stringify(req.additional_services)
//                       ),
//                     });
//                   }}
//                 >
//                   <span
//                     className={`icon-${
//                       !as1.to_delete ? "check-circle" : "delete"
//                     } transition`}
//                   ></span>
//                   {/*{cp1.to_delete ? 'Add' : 'Del'}*/}
//                 </div>
//               </div>
//             </div>
//           );
//         })}
//         {addAs && (
//           <>
//             <div className="form__row">
//               <div className="form__row-inner d-flex">
//                 <div className="form__row-field">
//                   <Autocomplete
//                     getOptionKey={(o: any) => o.id}
//                     getOptionLabel={(o: any) => o.name}
//                     options={options["test"] || null}
//                     // error={error}
//                     loading={loading}
//                     onChange={(e, v) => {
//                       console.log(v);
//                       aserv.service = v;
//                       setAserv(JSON.parse(JSON.stringify(aserv)));
//                     }}
//                     value={aserv.service || null}
//                     isOptionEqualToValue={(o: any, v: any) => o.id === v.id}
//                     onInputChange={handleInputChange("test")}
//                     renderInput={(params) => {
//                       return (
//                         <TextField
//                           {...params}
//                           label="Вид услуги"
//                           variant="outlined"
//                         />
//                       );
//                     }}
//                     // className={className}
//                   />
//                 </div>
//                 <div className="form__row-field">
//                   <TextField
//                     type="number"
//                     value={aserv.cost}
//                     label=""
//                     onChange={(e) => {
//                       aserv.cost = e.target.value;
//                       setReq({
//                         ...req,
//                         additional_services: JSON.parse(
//                           JSON.stringify(req.additional_services)
//                         ),
//                       });
//                     }}
//                     className="form__row-input"
//                   />
//                 </div>
//                 <div
//                   className="form__row-action d-flex block-center"
//                   onClick={() => {
//                     req.additional_services.push(aserv);
//                     setReq({
//                       ...req,
//                       additional_services: JSON.parse(
//                         JSON.stringify(req.additional_services)
//                       ),
//                     });
//                     setAserv({});
//                   }}
//                 >
//                   <span
//                     className={`icon-${
//                       !aserv.to_delete ? "check-circle" : "delete"
//                     } transition`}
//                   ></span>
//                   {/*{cp1.to_delete ? 'Add' : 'Del'}*/}
//                 </div>
//               </div>
//             </div>
//           </>
//         )}
//         {!addAs && (
//           <button
//             className="form__add d-flex items-center transition"
//             type="button"
//             onClick={() => {
//               setAddAs(true);
//             }}
//           >
//             Добавить услугу
//           </button>
//         )}
//       </div>
//     </>
//   );
// };

export default function ReqEdit() {
  // const reqId = props.match.params.id
  const navigate = useNavigate();
  const { reqId } = useParams();
  const r: any = { additional_services: [] };
  const [req, setReq] = useState<Req | null>(null);
  const rh: any[] = [];
  const [reqHistory, setReqHistory] = useState(rh);
  const [firstUpdated, setFirstUpdated] = useState(false);
  const baseErrors: any = {};
  const [snackbarMessage, setSnackbarMessage] = useState("");
  // const [errors, setErrors] = useState(baseErrors);
  // const [addAs, setAddAs] = useState(false);
  // const [aserv, setAserv] = useState({});
  // const [opened, { close, open }] = useDisclosure(false);
  // const {me}: {me: any } = useOutletContext()
  const [snackbarOpened, snackbarActions] = useDisclosure(false);
  const { modalStates, toggleModal } = useConfirmModals();

  useEffect(() => {
    const fetchData = async () => {
      const res = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/reqs/${reqId}/`,
        {},
        navigate
      );
      const res2 = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/reqs/${reqId}/history/`,
        {},
        navigate
      );
      setReq(await res.json());
      setReqHistory(await res2.json());
      setFirstUpdated(true);
    };
    fetchData();
  }, [reqId, navigate]);

  const handleCancelRequest = async (modal: string) => {
    const res = await myFetch(
      `${SETTINGS.SITE_URL}/api/v1/reqs/${reqId}/`,
      { method: "PATCH", body: JSON.stringify({ status: "Отменена" }) },
      navigate
    );
    if (!res.ok) {
      const jsonData = await res.json();
      snackbarActions.open();
      setSnackbarMessage(`Ошибка: ${Object.values(jsonData)[0] as string}`);
      toggleModal(`${modal}_confirm`);
      toggleModal(modal);
    } else {
      navigate("/reqs");
    }
  };

  const modalConfirmConfig: FormConfirmModals = useMemo(
    () => ({
      configs: MODAL_EDIT_CONFIGS,
      states: modalStates,
      handlers: {
        refuse: {
          onConfirm: () => toggleModal("refuse_confirm"),
          onClose: () => toggleModal("refuse"),
        },
        refuse_confirm: {
          onConfirm: () => handleCancelRequest("refuse"),
          onClose: () => {
            toggleModal("refuse_confirm");
            toggleModal("refuse");
          },
        },
        idleFlight: {
          onConfirm: () => toggleModal("idleFlight_confirm"),
          onClose: () => toggleModal("idleFlight"),
        },
        idleFlight_confirm: {
          onConfirm: () => handleCancelRequest("idleFlight"),
          onClose: () => {
            toggleModal("idleFlight_confirm");
            toggleModal("idleFlight");
          },
        },
        overloading: {
          onConfirm: () => toggleModal("overloading_confirm"),
          onClose: () => toggleModal("overloading"),
        },
        overloading_confirm: {
          onConfirm: () => handleCancelRequest("overloading"),
          onClose: () => {
            toggleModal("overloading_confirm");
            toggleModal("overloading");
          },
        },
      },
    }),
    [modalStates]
  );

  if (!req) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Snackbar
        open={snackbarOpened}
        autoHideDuration={SNACKBAR_CONFIG.autoHideDuration}
        onClose={snackbarActions.close}
        anchorOrigin={SNACKBAR_CONFIG.anchorOrigin}
        message={snackbarMessage}
      />
      <EditConfirmModals {...modalConfirmConfig} />
      <div className="application-page d-flex flex-wrap">
        <div className="page-top page-top--black d-flex items-center">
          <div className="page-top__left d-flex items-center">
            <div
              className="page-top__back circle-center"
              onClick={() => navigate(-1)}
            >
              <span className="icon-angle-left"></span>
            </div>
            <span className="page-top__title">Заявка #{req.id}</span>
            <div className="page-top__status completed d-flex items-center">
              {req.status}
            </div>
            {/* Завершен - completed, В работе - in-work */}
          </div>

          <div className="page-top__right d-flex items-center">
            <div className="page-top__created d-flex items-center">
              <div className="page-top__created-icon circle-center">
                <span className="icon-calendar"></span>
              </div>
              <span className="page-top__created-date">
                Создана от {getDateFormat(req.created_at, DATE_FORMAT_OPTIONS)}·{" "}
                {getTime(req.created_at)}
              </span>
              <span className="page-top__created-editing">
                Изменена {getDateFormat(req.updated_at, DATE_FORMAT_OPTIONS)}·{" "}
                {getTime(req.updated_at)}
              </span>
            </div>

            <div className="truck-info d-flex items-center">
              <div className="truck-info__icon circle-center">
                <span className="icon-truck"></span>
              </div>

              <span className="truck-info__text d-flex items-center">
                Авто{" "}
                <span>
                  · KAMAZ · 8 кубов ·{" "}
                  {req.driver?.current_vehicle?.license_plate}
                </span>
              </span>
            </div>

            <div className="spent-fuel d-flex items-center">
              <div className="spent-fuel__icon circle-center">
                <span className="icon-drop"></span>
              </div>
              <span className="spent-fuel__text">
                Топливо потрачено <span>· 308 литров</span>
              </span>
            </div>
          </div>
        </div>

        <div className="application-page__top d-flex">
          <div
            className="application-page__top-item d-flex items-center transition"
            onClick={() =>
              navigate("/", {
                state: {
                  key: "req",
                  data: { req_id: req.id },
                },
              })
            }
          >
            <span className="icon-map transition"></span>
            Посмотреть на карте
          </div>
          <div
            className="application-page__top-item d-flex items-center transition"
            onClick={() => toggleModal("refuse")}
          >
            <span className="icon-close-circle transition"></span>
            Отказ от заявки
          </div>
          <div
            className="application-page__top-item d-flex items-center transition"
            onClick={() => toggleModal("idleFlight")}
          >
            <span className="icon-danger transition"></span>
            Холостой рейс
          </div>
          <div
            className="application-page__top-item d-flex items-center transition"
            onClick={() => toggleModal("overloading")}
          >
            <span className="icon-cube transition"></span>
            Перегруз автотранспорта
          </div>
        </div>

        <div className="application-page__content">
          <EditReqForm req={req} />
          {/* <MyForm
            fields={reqEditFields}
            method="PUT"
            returnUrl={`/reqs/`}
            formUrl={`${SETTINGS.SITE_URL}/api/v1/reqs/${reqId}/`}
            state={req}
            setState={setReq}
            firstUpdated={firstUpdated}
            errors={errors}
            setErrors={setErrors}
            additionalFields={
              <ReqAdditionalFields
                reqId={reqId}
                req={req}
                setReq={setReq}
                addAs={addAs}
                aserv={aserv}
                setAddAs={setAddAs}
                setAserv={setAserv}
                errors={errors}
                setErrors={setErrors}
              />
            }
          /> */}
        </div>

        <div className="application-page__history">
          <h2 className="form__title">История заявки</h2>
          <div className="history-list">
            <div className="history-item history-item--green">
              {/* add class 'history-item--green' or 'history-item--orange' */}
              <div className="history-item__inner d-flex flex-wrap">
                <div className="history-item__info d-flex justify-between">
                  <div className="history-item__info-column d-flex flex-column">
                    <span className="history-item__info-text">
                      {getDateFormat(req.created_at, {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })}
                      · {getTime(req.created_at)}
                    </span>
                    <span className="history-item__info-title">
                      Создание заявки
                    </span>
                  </div>
                  <div className="history-item__info-column d-flex flex-column">
                    <span className="history-item__info-text">Менеджер</span>
                    <span className="history-item__info-manager d-flex items-center">
                      {req.author?.full_name || "<Неизвестный>"}
                      <span className="icon-profile-black"></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {reqHistory.map((hist, i) => {
              let title = "Неизвестное действие";
              let myClass = "history-item--red";

              for (const key in hist.history_data) {
                if (
                  hist.history_data.hasOwnProperty(key) &&
                  historyDataMappings[key]
                ) {
                  title = historyDataMappings[key].title;
                  myClass = historyDataMappings[key].class;
                  break;
                }
              }
              return (
                hist && (
                  <div className={`history-item ${myClass}`} key={i}>
                    {/* add class 'history-item--green' or 'history-item--orange' */}
                    <div className="history-item__inner d-flex flex-wrap">
                      <div className="history-item__info d-flex justify-between">
                        <div className="history-item__info-column d-flex flex-column">
                          <span className="history-item__info-text">
                            {getDateFormat(
                              hist.created_at,
                              DATE_FORMAT_OPTIONS
                            )}
                            · {getTime(hist.created_at)}
                          </span>
                          <span className="history-item__info-title">
                            {title}
                          </span>
                        </div>
                        <div className="history-item__info-column d-flex flex-column">
                          <span className="history-item__info-text">
                            Менеджер
                          </span>
                          <span className="history-item__info-manager d-flex items-center">
                            {hist.changer?.full_name}
                            <span className="icon-profile-black"></span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
