const cAddFields = [
    {
        header: "Номер контейнера",
        fields: [
            {
                mykey: 'num',
                fieldKind: 'text',
                label: 'Номер',
            }
        ]
    }, {
        header: "Объем контейнера",
        fields: [
            {
                mykey: 'volume',
                fieldKind: 'number',
                label: 'Объем',
            }
        ]
    }
]

export default cAddFields