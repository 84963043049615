import { styled } from "@mui/material";
import {
  DatePicker as DatePickerMiu,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { FC } from "react";

type DatapickerStyles = {
  fz?: number;
};

const DatePickerMui = styled(DatePickerMiu)<DatapickerStyles>(({ fz }) => ({
  ".MuiInputLabel-root": {
    color: "#B2B3C3 !important",
    fontSize: "10px",
    top: "-6px !important",
  },
  ".MuiInputLabel-root[data-shrink='true']": {
    color: "#B2B3C3 !important",
    opacity: "1 !important",
    top: "16px !important",
  },
  ".MuiInputBase-root": {
    borderRadius: "10px",
    height: "100%",

    "& fieldset": {
      borderRadius: "10px",

      boxShadow: "4px 3px 2px #F6F6F9",
      borderColor: "var(--input-border-color) !important",
    },
  },
  ".MuiInputBase-root fieldset legend": {
    display: "none",
  },
  ".MuiInputBase-root input": {
    padding: "22px 0 4px 16px",
    fontSize: `${fz}px` ?? "12px",
  },
  ".MuiInputBase-root fieldset": {
    top: 0,
  },
}));

type DatePickerProps = {
  onChange: (e: dayjs.Dayjs | null) => void;
  label?: string;
  defaultValue?: string;
};
export const DatePicker: FC<DatePickerProps & DatapickerStyles> = ({
  onChange,
  label,
  defaultValue,
  fz,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
      <DatePickerMui
        label={label}
        format="DD MMM YYYY"
        fz={fz}
        slotProps={{
          textField: {
            variant: "outlined",
          },
        }}
        defaultValue={dayjs(defaultValue)}
        onChange={onChange}
      />
    </LocalizationProvider>
  );
};
