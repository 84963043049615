import { Stack } from "@mui/material";
import { Title } from "../../../../../ui/mui/Title/Title";
import { Group } from "../../../../../ui/mui/Group/Group";
import { InputForm } from "../../../../../ui/mui/Input/Input";
import { SelectForm } from "../../../../../ui/mui/Select/Select";
import { FC } from "react";
import { Req } from "../../../../../interfaces/req";

type PaymentSectionProps = {
  onChange: (label: string) => (value: string | number) => void;
  disabled?: boolean;
  req: Req;
};
export const PaymentSection: FC<PaymentSectionProps> = ({
  onChange,
  disabled,
  req,
}) => {
  return (
    <Stack gap={2}>
      <Title variant="h4">Заявка</Title>
      <Stack gap={2}>
        <Group gap={10}>
          <InputForm
            label="Сумма оплаты"
            defaultValue={req.total_sum}
            disabled={disabled}
            onChange={(e) => onChange("total_sum")(+e.target.value)}
          />
          <SelectForm
            list={["Расчетный счет", "Наличные"]}
            label="Тип оплаты"
            w={190}
            defaultValue={req.payment_type}
            disabled={disabled}
            onChange={(e) => onChange("payment_type")(e.target.value)}
          />
          <SelectForm
            list={["Оплачен", "Не оплачен"]}
            label="Статус оплаты"
            w={190}
            defaultValue={req.payment_status}
            disabled={disabled}
            onChange={(e) => onChange("payment_status")(e.target.value)}
          />
        </Group>
        <InputForm
          label="Комментарий"
          defaultValue={req.comment}
          w={575}
          disabled={disabled}
          onChange={(e) => onChange("comment")(e.target.value)}
        />
      </Stack>
    </Stack>
  );
};
