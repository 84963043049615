import { useCallback, useMemo, useState } from "react";
import { fetchWialonVehicleLocations } from "../../api/wialon/request";
import { WialonVehicleLocations } from "../../interfaces/vehicle";
import { useNavigate } from "react-router-dom";

type WialonVehiclesLocationType = () => {
  vehicleLocations: WialonVehicleLocations[] | null;
  getVehicleLocations: (abortSignal?: AbortSignal) => Promise<void>;
  error: string | null;
};

export const useWialonVehiclesLocation: WialonVehiclesLocationType = () => {
  const navigate = useNavigate();
  const [vehicleLocations, setVehicleLocations] = useState<
    WialonVehicleLocations[] | null
  >(null);
  const [error, setError] = useState<string | null>(null);

  const getVehicleLocations = useCallback(async (abortSignal?: AbortSignal) => {
    try {
      const response = await fetchWialonVehicleLocations(navigate, abortSignal);

      if (!response.ok) {
        const errorData = await response.json();
        let errorMessage = "Произошла ошибка";

        if (response.status === 404) {
          errorMessage =
            errorData.message || "На сегодня не найдено активных автомобилей.";
        } else if (response.status === 400) {
          errorMessage =
            errorData.error || "Не удалось получить данные из Wialon.";
        } else {
          errorMessage = `Ошибка: ${response.status}`;
        }

        throw new Error(errorMessage);
      }
      const data = await response.json();
      setVehicleLocations(data.results);
      setError(null);
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error("Ошибка при выполнении запроса:", error);
        setVehicleLocations(null);
        setError(error.message);
      } else {
        console.error("Неизвестная ошибка:", error);
        setError("Произошла неизвестная ошибка");
      }
    }
  }, []);

  return useMemo(
    () => ({ vehicleLocations, getVehicleLocations, error }),
    [vehicleLocations, getVehicleLocations, error]
  );
};
