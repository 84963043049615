const driverAddFields = [
    {
        header: "Личные данные",
        fields: [
            {
                mykey: 'last_name',
                fieldKind: 'text',
                label: 'Фамилия',
            },
            {
                mykey: 'first_name',
                fieldKind: 'text',
                label: 'Имя',
            },
            {
                mykey: 'second_name',
                fieldKind: 'text',
                label: 'Отчество',
            },
        ]
    }, {
        header: "Связь",
        fields: [
            {
                mykey: 'phone',
                fieldKind: 'text',
                label: 'Телефон',
            },
            {
                mykey: 'telegram',
                fieldKind: 'text',
                label: 'Телеграм',
            },
            {
                mykey: 'whatsapp',
                fieldKind: 'text',
                label: 'WhatsApp',
            },
        ],
    },
    {
        header: "Статус",
        fields: [
            {
                mykey: 'status',
                fieldKind: 'select',
                nameField: 'name',
                options: [
                    {
                        name: 'Готов',
                    },
                    {
                        name: 'Выходной',
                    },
                    {
                        name: 'Больничный',
                    },
                    {
                        name: 'Отпуск',
                    },
                    {
                        name: 'Отгул',
                    }
                ]
            }
        ]
    }
]

export default driverAddFields