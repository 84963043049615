import { Stack } from "@mui/material";
import { Group } from "../../../../ui/mui/Group/Group";
import { ViewingField } from "../../../../ui/Fields/ViewingField";
import { SelectForm } from "../../../../ui/mui/Select/Select";
import { routeConfig } from "../../../../params/req";
import { FC } from "react";

export const RouteState: FC<{
  routeState: string;
  address_location: string;
  isReqFinished: boolean;
}> = ({ routeState, address_location, isReqFinished }) => {
  const config = routeConfig[routeState];
  if (!config) return null;

  return (
    <Stack gap={1.4}>
      {config.map((item, index) => (
        <Group gap={10} key={index}>
          <ViewingField
            label={item.label}
            value={address_location}
            w={79}
            disabled={item.disabled}
          />
          {item.hasContainer && (
            <SelectForm
              list={null}
              label="Выберите контейнер"
              w={190}
              disabled={isReqFinished}
              developed
            />
          )}
        </Group>
      ))}
    </Stack>
  );
};
